import { React, Snackbar, Alert } from 'mcelroy-lib'

/*
  Context for keeping track of the toast functionality
*/
const ToastContext = React.createContext(null)

/*
  Hook to allow child components of ToastContextProvider to get toast functionality
*/
export function useToast() {
    /*!
        React hook to display a notifcation toast at top of screen.

        Example:
        import {useToast} from 'mcelroy-components'

        const toast = useToast()
        
        toast.error("Message")
        toast.warn("Message")
        toast.info("Message")
        toast.success("Message")
    */
    return React.useContext(ToastContext)
}

/*
  Component that handles creating the toast context
*/
export function ToastContextProvider({ children }) {
    const [messages, setMessages] = React.useState([])
    const [currentToast, setCurrentToast] = React.useState(null)

    React.useEffect(function () {
        if (messages.length && !currentToast) {
            setCurrentToast({ ...messages[0] })
            setMessages((prev) => prev.slice(1))
        }
    }, [messages, currentToast])

    function addMessage(msg, type) {
        setMessages((prev) => [...prev, { msg, type, key: new Date().getTime() }])
    }

    function error(msg) {
        addMessage(msg, 'error')
    }

    function warn(msg) {
        addMessage(msg, 'warning')
    }

    function info(msg) {
        addMessage(msg, 'info')
    }

    function success(msg) {
        addMessage(msg, 'success')
    }

    function handleClose(event, reason) {
        if (reason !== 'clickaway') {
            setCurrentToast(null)
        }
    }

    return (
        <ToastContext.Provider value={{ error, warn, info, success }}>
            <Snackbar
                key={currentToast ? currentToast.key : undefined}
                open={currentToast != null}
                autoHideDuration={3000}
                onClose={handleClose}
                TransitionProps={{ onExited: handleClose }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{marginTop: '100px'}}
            >
                <Alert severity={currentToast?.type} variant="filled">{currentToast?.msg}</Alert>
            </Snackbar>
            {children}
        </ToastContext.Provider>
    )
}
