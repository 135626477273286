import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#007041',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#073E69',
    },
    alt1: {
      main: '#A32E00',
      contrastText: '#ffffff',
    },
    alt2: {
      main: '#A35D00',
      contrastText: '#ffffff',
    },
    gray: {
      main: '#555',
      contrastText: '#ffffff',
    },
    error: {
      main: '#A30000',
    },
  },
});

export default theme;
