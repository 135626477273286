import { React, FormControlLabel, Checkbox } from 'mcelroy-lib'

/*
    Simple checkbox
*/
export function FormCheckField({ label, value, name, disabled, helperText, dispatch }) {
    const [currentValue, setCurrentValue] = React.useState(value == true)

    React.useEffect(() => {
        dispatch({ name: name, value: currentValue, from: 'effect' })
    }, [name, currentValue])

    React.useEffect(() => {
        setCurrentValue(value)
    }, [value])

    return (
        <FormControlLabel
            label={label}
            disabled={disabled}
            control={
                <Checkbox checked={currentValue == true} onChange={(event) => { setCurrentValue(!currentValue) }} />
            }
        />
    )
}
