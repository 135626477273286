import { React, ReactRouter, Button as ButtonMUI } from 'mcelroy-lib'

export function Button({ to, onClick, color, variant, disabled, children }) {
    /*!
        Wrapper around MUI Button and ReactRouter.Link

        @to: follows React Router path
        @onClick: standard React onClick handler
        @color: MUI color [primary, secondary, ...]
        @variant: MUI variant [contained, text, ...]
        @diabled:
        @children: button text
    */
    return (
        <ButtonMUI component={ReactRouter.Link} to={to} color={color} variant={variant} onClick={onClick} disabled={disabled}>
            {children}
        </ButtonMUI>
    )
}
