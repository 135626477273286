import { React, Icon as IconMUI } from 'mcelroy-lib'

export function Icon({ color, style, children }) {
    /*!
        Wrapper around MUI Icon

        @to: follows React Router path
        @color: MUI color [primary, secondary, ...]
        @style: standard react html style
    */
    return (
        <IconMUI color={color} style={style}>
            {children}
        </IconMUI>
    )
}
