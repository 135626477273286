import { React, TextField, MenuItem } from 'mcelroy-lib'

/*
    Simple text field/select box with validator
*/
export function FormTextField({ label, value, name, type, options, disabled, validator, helperText, dispatch }) {
    const [currentValue, setCurrentValue] = React.useState(value)
    const [errorMessage, setErrorMessage] = React.useState(validator?.(value))

    React.useEffect(() => {
        dispatch({ type: errorMessage != null ? 'add' : 'remove', name: name, value: currentValue, from: 'effect' })
    }, [name, currentValue, errorMessage])

    React.useEffect(() => {
        setCurrentValue(value)
    }, [value])

    React.useEffect(() => {
        setErrorMessage(validator?.(currentValue))
    }, [validator])

    if (Array.isArray(options)) {
        return (
            <TextField fullWidth margin="normal"
                name={name}
                label={label}
                value={currentValue}
                select
                disabled={disabled}
                error={errorMessage ? true : false}
                helperText={errorMessage ? errorMessage : helperText}
                onChange={(event) => {
                    const msg = validator?.(event.target.value)
                    if (msg != null && errorMessage == null)
                        setErrorMessage(msg)
                    else if (msg == null && errorMessage != null)
                        setErrorMessage(null)

                    setCurrentValue(event.target.value)
                }}
            >
                {options.map(function (o) {
                    return (
                        <MenuItem key={o.value} value={o.value} disabled={o.disabled}>{o.label}</MenuItem>
                    )
                })}
            </TextField>
        )
    } else {
        const inputProps = {}
        if (type === 'new-password')
            inputProps.autocomplete = 'new-password'
        return (
            <TextField fullWidth margin="normal"
                InputLabelProps={{ shrink: true }}
                inputProps={inputProps}
                name={name}
                label={label}
                value={currentValue}
                type={type === 'new-password' ? 'password' : type}
                disabled={disabled}
                error={errorMessage ? true : false}
                helperText={errorMessage ? errorMessage : helperText}
                onChange={(event) => {
                    const msg = validator?.(event.target.value)
                    if (msg != null && errorMessage == null)
                        setErrorMessage(msg)
                    else if (msg == null && errorMessage != null)
                        setErrorMessage(null)

                    setCurrentValue(event.target.value)
                }}
            />
        )
    }
}
