import { React, ReactRouter, makeStyles } from 'mcelroy-lib'

const useDataTableStyles = makeStyles(theme => ({
    clickable: {
        "& tr:hover": {
            cursor: "pointer",
            backgroundColor: '#b4d4c6 !important'
        }
    },
    pulse: {
        backgroundColor: 'white',
        animationName: `$pulse`,
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
    },
    "@keyframes pulse": {
        "0%": {
            backgroundColor: 'white'
        },
        "50%": {
            backgroundColor: 'lightgrey'
        },
        "100%": {
            backgroundColor: 'white'
        }
    }
}))

/*
    DataTable element
*/
export function DataTable({ columns, data, loading, options, stickyTop }) {
    const navigate = ReactRouter.useNavigate()

    // when row is clicked do someting base on options
    function handleClick(rowData) {
        if (options.rowClickTo) {
            let to = options.rowClickTo

            // replace :var placeholders with row data
            for (const k in rowData)
                to = to.replace(':' + k, rowData[k])

            // navigate to new url location
            navigate(to)
        }
    }

    return (
        <table style={{ minWidth: '100%', textAlign:'left' }}>
            <DataTableHeader columns={columns} stickyTop={stickyTop} />
            <DataTableRows columns={columns} data={data} loading={loading} options={options} handleClick={handleClick} />
        </table>
    )
}

/*
    Table header based on columns
*/
function DataTableHeader({ columns, stickyTop }) {
    const style = {}
    if (stickyTop != null) {
        style.position = 'sticky'
        style.top = stickyTop
        style.backgroundColor = '#ddd'
        
    }

    return (
        <tbody style={style}>
            <tr>
                {columns?.map(function (c, i) {
                    return (
                        <th style={{padding:'0.2rem 0.5rem', fontSize:'1.05rem'}} key={i}>{c.title ?? c.name}</th>
                    )
                })}
            </tr>
        </tbody>
    )
}

/*
    Table body displaying all data rows
*/
function DataTableRows({ columns, data, loading, options, handleClick }) {
    const dataTableStyles = useDataTableStyles();

    const oddrowstyle = {
        backgroundColor: '#f0f0f0'
    }

    if (loading) {
        return (
            <tbody>
                {[1, 2, 3].map(function (r, ri) {
                    return (
                        <tr key={ri} >
                            {columns.map(function (c, i) {
                                return (
                                    <td key={i} style={{ padding: '0.2rem 0.5rem' }}><div className={dataTableStyles.pulse}>&nbsp;</div></td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        )
    } else {
        const rows = options?.limit ? data.slice(0, options.limit) : data
        return (
            <tbody className={options?.rowClickTo != null ? dataTableStyles.clickable : null}>
                {rows?.map(function (r, i) {
                    const key = options?.keyColumn ? 'k' + r[options.keyColumn] : i
                    return (
                        <tr key={key} data-ex={key} onClick={(e) => { handleClick(r) }} style={i % 2 === 0 ? {}: oddrowstyle}>
                            {columns?.map(function (c, j) {
                                if (c.allowUnsafeHTML) {
                                    return (
                                        <td style={{ padding: '0.2rem 0.5rem' }} dangerouslySetInnerHTML={{__html: r[c.name]}} key={j}></td>
                                    )
                                } else{
                                    return (
                                        <td style={{ padding: '0.2rem 0.5rem' }} key={j}>{r[c.name]}</td>
                                    )
                                }
                                
                            })}
                        </tr>
                    )
                })}
            </tbody>
        )
    }
}

/*
    Footer at end of page to indicat there are more records available
    and to use search/filters to narrow down data records
*/
export function DataTableMore({ data, options }) {
    if (data && options?.limit && options.limit < data.length)
        return (<div style={{ margin: '50px' }}>More rows available...</div>)
    else
        return null
}
