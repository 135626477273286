import { React, Button } from 'mcelroy-lib'

/*
    Creates the footer buttons from the button data
    Primary on the right and caution on the left with others in the middle
*/
export function FormFooter({ buttons, valid, values }) {
    const elements = []

    function doClick(event, b) {
        const savedBackgroundColor = event.target.style.backgroundColor
        event.target.disabled = true
        event.target.style.backgroundColor = 'gray'

        Promise.resolve(b.onClick(values, event))
            .finally(function () {
                event.target.disabled = false
                event.target.style.backgroundColor = savedBackgroundColor
            })
    }

    function build(b) {
        const disabled = b.primary && !valid
        const color = b.primary ? 'primary' : b.caution ? 'error' : 'gray'
        return (
            <Button key={b.label}
                variant="outlined"
                color={color}
                onClick={(event) => { doClick(event, b) }}
                disabled={disabled}>
                {b.label}
            </Button>
        )
    }

    if (Array.isArray(buttons)) {
        for (const b of buttons) {
            if (b.primary)
                elements.push(build(b))
        }
        for (const b of buttons) {
            if (!b.primary && !b.caution)
                elements.push(build(b))
        }
        for (const b of buttons) {
            if (!b.primary && b.caution)
                elements.push(build(b))
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", width: "100%" }} >
            {elements.length > 0 ? elements : null}
        </div>
    )
}


