import { React, ReactRouter, TextField, Icon , Typography, Button} from 'mcelroy-lib'
import { BasicPage } from '../BasicPage'
import { DataTable, DataTableMore } from './DataTable'

export function TablePage({ pageTitle, columns, data, loading, options, actions }) {
    /*!
        Displays rows of data in a table format.  Uses BasicPage for title layout.

        @pageTitle: Override page title with custom text instead of navigation title
        @columns: column options
        {
            title: Display header
            name: data field
        }
        @data: Data rows
        [
            {
            ... data fields
            }
        ]
        @loading: true if data still loading from remote server. Displays gray faiding loading boxes
        @options: table related options
        {
            limit: number of rows to limit to
            keyColumn: field to use as React key on record. defaults to counted row number
            rowClickTo: path to navigate to when clicking a row.  Replaced :fieldName with field value of that row
        }
        @actions: array of button actions to show at top of page
        [
            {
                label: button title
                onClick: function to run on click
                to: path to navigate to instead us using onClick
            }
        ]
    */

    // url query parameters
    const [queryParams, setQueryParams] = ReactRouter.useSearchParams()
    const navigate = ReactRouter.useNavigate()

    // filter data rows using search query parameter 
    const search = queryParams.get('search')
    const rows = filterRowsBySearch(columns, data, search)

    // update url query param and replace url in history causing search variable to get updated
    function handleSearchInputChange(e) {
        const input = e.target.value?.trim()
        if (input != search) {
            setQueryParams({ ...queryParams, search: input }, { replace: true })
        }
    }

    const actionButtons = actions ?? []

    return (
        <BasicPage pageTitle={pageTitle}>
            <SearchBox options={options} search={search} handleSearchInputChange={handleSearchInputChange}/>
            <span slot="title" style={{ paddingTop: '10px', paddingLeft: '30px' }}>
                <Typography component="span" color="gray.main">
                    {actionButtons.map((b) => {
                        return (
                            <Button key={b.label}
                                sx={{ marginLeft: '10px' }}
                                variant="outlined"
                                onClick={b.onClick ?? function(){if(b.to) navigate(b.to)}}>
                                {b.label}
                            </Button>
                        )
                    })}
                </Typography>
            </span>
            <DataTable columns={columns} data={rows} loading={loading} options={options} stickyTop='46px' />
            <DataTableMore data={rows} options={options} />
        </BasicPage>
    )
}

/*
    Show search box if a limit was set in options
*/
function SearchBox({ options, search, handleSearchInputChange }) {
    if (options?.limit == null)
        return null

    return (
        <span slot="title" style={{ flexGrow: 1, paddingTop: '10px', paddingLeft: '30px' }}>
            <Typography component="span" color="gray.main">
                <Icon fontSize='large'>search</Icon>
                <TextField size="small" onChange={handleSearchInputChange} defaultValue={search} />
            </Typography>
        </span>
    )
}

/*
    filter data based on search
*/
function filterRowsBySearch(columns, data, search) {
    let rows = data
    if (data && search && search.trim() != '') {
        rows = rows.filter(function (r) {
            let match = true
            const searchWords = search.split(' ')
            for (const s of searchWords) {
                let good = false
                const sLower = s.trim().toLowerCase()
                for (const c of columns) {
                    const rowVal = r[c.name]?.toString().toLowerCase()
                    if (rowVal && rowVal.includes(sLower))
                        good = true
                }
                match &= good
            }
            return match
        })
    }
    return rows
}
