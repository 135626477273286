import {
    React,
    ReactRouter,
    Box,
    Stack,
    Button,
    Drawer
} from 'mcelroy-lib'
import { UserAccount, useUserContext } from './user'

/*
    Component that handles standard app nav links
    Nav links only on larger screen
*/
export function AppNavLinks({ routes }) {

    {/* Get current location */ }
    const location = ReactRouter.useLocation()

    {/* Get current user */ }
    const { user } = useUserContext()

    return (
        <Stack direction="row" spacing={2} sx={{ display: ['none', null, 'block'] }}>
            {routes
                .filter(route => (route.handle?.title)) // filter out routes that don't have a title
                .filter(route => (route.handle?.active == null || route.handle?.active(user))) // don't show inctive routes
                .map((route, index) => (
                    <Button
                        variant={location.pathname == route.path ? 'contained' : 'text'}
                        color="gray"
                        component={ReactRouter.Link}
                        to={route.path}
                        key={index}>
                        {route.handle.title}
                    </Button>
                ))
            }
        </Stack>
    )
}

/*
    Component to handle hiding/showing navigation drawer on smaller screens
*/
export function DrawerNavLinks({ routes, isOpen, toggleOpen }) {
    const drawerWidth = 240
    const container = window.document.body

    {/* Get current user */ }
    const { user } = useUserContext()

    return (
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={isOpen}
                onClose={toggleOpen}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: ['block', null, 'none'],
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                <Box onClick={toggleOpen} sx={{ textAlign: 'center' }}>
                    <Stack spacing={1}>
                        <UserAccount />
                        {routes
                            .filter(route => (route.handle?.title)) // filter out routes that don't have a title
                            .filter(route => (route.handle?.active == null || route.handle?.active(user))) // don't show inctive routes
                            .map((route, index) => (
                                <Button
                                    variant={location.pathname == route.path ? 'contained' : 'text'}
                                    color="gray" component={ReactRouter.Link}
                                    to={route.path}
                                    key={index}>
                                    {route.handle.title}
                                </Button>
                            ))
                        }
                    </Stack>
                </Box>
            </Drawer>
        </Box>
    )
}
