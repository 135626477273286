import { React } from 'mcelroy-lib'
import { CardPage } from '../CardPage'
import { Form } from './Form'
import { FormFooter } from './FormFooter'

export function FormPage({ title, size, fields, buttons, pageTitle, children }) {
    /*!
        Displays and validates a form from passed in field data.  Uses CardPage for base layout.

        @title: Text to display at top of card
        @size: Size of card
        @fields: Form Elements
        {
            label: field label
            value: initial field value
            type: text (default), password
            disabled:
            name: name of data when passed into primary buttons
            helperText: text shown below field
            validator: function(val) validation function return error message or null
            options: ignores type and creates a dropdown select box instead
            {
                label: option display
                value: option value
                disabled:
            }
        }
        @buttons: Footer button elements
        {
            label: label on button,
            primary: use primary coloring
            caution: use error coloring
            onClick: function(values, event)

            * if onClick returns a Promise then the button will be disabled until the promise resolves
        }
        @pageTitle: Override page title with custom text instead of navigation title
    */

    function reduce(state, action) {
        const result = { errors: [...state.errors], values: state.values }
        result.values[action.name] = action.value
        if (action.type == 'add') {
            if (!state.errors.includes(action.name)) {
                result.errors.push(action.name)
            }
        } else if (action.type == 'remove') {
            if (state.errors.includes(action.name)) {
                result.errors.splice(result.errors.indexOf(action.name), 1)
            }
        }
        return result
    }

    const [state, dispatch] = React.useReducer(reduce, { errors: [], values: {} })
    const formRef = React.useRef()

    function isValid() {
        // if the form is reused and fields changed then the state may have leftover old
        // errored fields so just check against current field list
        const inputElements = formRef?.current?.getElementsByTagName('input')
        if (inputElements) {
            const elementNames = []
            for (const ie of inputElements)
                elementNames.push(ie.name)
            for (const err of state.errors) {
                if (elementNames.includes(err))
                    return false
            }
        }
        return true
    }

    return (
        <CardPage pageTitle={pageTitle} size={size} title={title} footer={<FormFooter buttons={buttons} valid={isValid()} values={state.values} />}>
            <div ref={formRef}>
                <Form fields={fields} dispatch={dispatch} />
                {children}
            </div>
        </CardPage>
    )
}
