import {
  React,
  ReactRouter,
  CssBaseline,
  ThemeProvider,
  Box,
  AppBar,
  Toolbar,
  Grid,
  Link,
  IconButton,
  Typography,
  Icon
} from 'mcelroy-lib'
import { UserContextProvider, UserAccount } from './user'
import { AppNavLinks, DrawerNavLinks } from './navigation'
import { ToastContextProvider } from './toast'
import theme from './theme'

const globalLinks = [
  { title: 'Buy Equipment', url: 'http://www.mcelroy.com/en/distributorlocator.htm' },
  { title: 'Rent Equipment', url: 'http://www.mcelroy.com/en/rentequipment.htm' },
  { title: 'Parts Finder', url: 'http://fusion.mcelroy.com/parts/exec' },
  { title: 'Distributor Portal', url: 'http://sales.mcelroy.com/apps/index.htm/default' }
]

/*
  McElroy styled app layout.
  Handles transitioning navigation between large and small screens.
  Handles navigation links based on routes passed in from web app using this component.
  All child pages passed in end up wrapped in this layout.
*/
function McElroyAppLayout({ title, routes, login }) {

  // State management for navigation drawer on smaller screens
  const [isDrawerOpen, setDrawerOpen] = React.useState(false)
  const toggleDrawerOpen = () => { setDrawerOpen((prevState) => !prevState) }

  return (
    <ThemeProvider theme={theme}>

      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />

      <UserContextProvider><ToastContextProvider>

        {/* Window */}
        <div style={{position: "fixed", top: 0, left: 0, right: 0, bottom: 0, overflow: "scroll"}}>

          {/* Global nav bar -- not shown on small screen*/}
          <AppBar position="static" color="gray" sx={{ display: ['none', null, 'block'] }}>
            <Toolbar variant="dense">

              <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                {globalLinks.map((item, index) => (
                  <Grid item key={index}>
                    <Link variant='body2' color='primary.contrastText' underline='none' href={item.url}>
                      {item.title}
                    </Link>
                  </Grid>
                ))}
                <Grid item>
                  <UserAccount login={login} />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          {/* Application nav bar -- primary color on small screen otherwise white */}
          <AppBar position="sticky" sx={{
            top: 0, left: 0, right:0,
            backgroundColor: ['primary.main', null, '#ffffff'],
            color: ['primary.contrastText', null, '#555']
          }}>
            <Toolbar variant="dense">

              {/* Menu icon only on small screen */}
              <IconButton onClick={toggleDrawerOpen} dge="start" color="inherit" aria-label="menu" sx={{ mr: 2, display: ['block', null, 'none'] }}>
                <Icon sx={{ 'verticalAlign': 'text-top', 'fontSize': '1.75rem' }}>menu</Icon>
              </IconButton>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {title}
              </Typography>

              {/* App bar navigation for larger screens*/}
              <AppNavLinks routes={routes} />
            </Toolbar>
          </AppBar>

          {/* Page content */}
          <Box>
            {/* Children rendered inside Outlet */}
            <ReactRouter.Outlet />
          </Box>

          {/* Drawer navigation for smaller screens*/}
          <DrawerNavLinks routes={routes} isOpen={isDrawerOpen} toggleOpen={toggleDrawerOpen} />
          
        </div>
      </ToastContextProvider></UserContextProvider>
    </ThemeProvider>
  )
}

/*
  Generic error page added at root level as a catch all
*/
function ErrorPage() {
  const error = ReactRouter.useRouteError()
  console.error(error)

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  )
}

/*
  App shell that web apps use as a component.
  It sets up the Rect router routes with McElroyAppLaout as the root.
*/
export function McElroyApp({ title, routes, login }) {
  const router = ReactRouter.createBrowserRouter([
    {
      path: "/",
      element: <McElroyAppLayout title={title} routes={routes} login={login} />,
      errorElement: <ErrorPage />,
      children: routes
    }
  ])

  return (
    <ReactRouter.RouterProvider router={router} />
  )
}
