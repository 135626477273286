import { React, ReactRouter, Link as LinkMUI } from 'mcelroy-lib'

export function Link({ to, color, variant, tabindex, children }) {
    /*!
        Wrapper around MUI Link and ReactRouter.Link

        @to: follows React Router path
        @color: MUI color [primary, secondary, inherit, ...]
        @variant: MUI variant [body, body2, ...]
    */
    return (
        <LinkMUI component={ReactRouter.Link} to={to} color={color} variant={variant} tabindex={tabindex}>
            {children}
        </LinkMUI>
    )
}
