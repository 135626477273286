import { React, useAPI, Button, Icon } from 'mcelroy-lib'

/*
  Context for keeping track of the logged-in user
*/
const UserContext = React.createContext(null)

/*
  Hook to allow child components of UserContextProvider to get logged-in user info
*/
export function useUserContext() {
    return React.useContext(UserContext)
}

/*
    Class to represent user data from API
    Adds additional methods
*/
class User {
    #user
    #internal = false

    constructor(data, headers) {
        if (data?.user) {
            this.#user = data.user
        }
        if (headers) {
            if (headers.get?.('internal') === 'true')
                this.#internal = true
        }
    }

    get id() {
        return this.#user?.id
    }

    get loggedIn() {
        return this.#user?.id != null
    }

    get internal() {
        return this.#internal
    }

    get firstName() {
        return this.#user?.firstName
    }

    get lastName() {
        return this.#user?.lastName
    }

    get isVendor() {
        return this.#user?.permissions?.vendor ? true : false
    }

    get isDistributor() {
        return this.#user?.permissions?.customer ? true : false
    }

    get isEmployee() {
        return this.#user?.permissions?.employee ? true : false
    }

    get isUserAdmin() {
        if (this.isEmployee && (this.#user?.permissions?.useradmin || this.#user?.permissions?.fulladmin))
            return true
        if (this.isDistributor && this.#user?.permissions?.custadmin)
            return true
        return false
    }

    get accounts() {
        const accounts = []
        if (this.#user?.distributorAccounts != null) {
            for (const act of this.#user.distributorAccounts) {
                accounts.push(act.id)
            }
        }
        return accounts
    }

    hasRoles(roles) {
        if (this.#user?.roles != null) {
            for (let r of roles) {
                if (this.#user.roles.indexOf(r) != -1)
                    return true
            }
        }
        return false
    }
}

/*
  Component that handles creating the user context and data
*/
export function UserContextProvider({ children }) {

    // Get logged-in user info so children can use the user context hook
    const { loading, data, error, status, rerun, headers } = useAPI(`
    query{
        user{
            id
            firstName
            lastName
            email
            roles
            permissions{
                vendor
                customer
                employee
                fulladmin
                useradmin
                custadmin
            }
            distributorAccounts{ id }
        }
    }`)

    const user = React.useMemo(() => {
        return new User(data, headers)
    }, [data])

    return (
        <UserContext.Provider value={{ loading, data, error, user, status, rerun }}>
            {children}
        </UserContext.Provider>
    )
}

/*
  Component that uses the user context hook to show if logged in or not
*/
export function UserAccount({login}) {
    const { loading, user } = useUserContext()
    const accountURL = 'https://sales.mcelroy.com/auth/external.svc?page=Login&sp=S&sp=S'
        + window.location.href + '&sp=S' + (user.loggedIn ? 'false' : '')

    if (loading || login === 'none') {
        return null
    } else if (!user.loggedIn && login === 'name only') {
        return null
    }
    return (
        <Button color="inherit" href={accountURL}>
            <Icon sx={{ 'marginRight': '.25rem' }}>account_circle</Icon>
            {user.loggedIn ? user.firstName : 'Log In'}
        </Button>
    )
}
