import { React, Container, Typography, Card, CardContent, CardActions } from 'mcelroy-lib'
import { BasicPage } from './BasicPage'

export function CardPage({ title, size, footer, pageTitle, children }) {
    /*!
        Displays content in a card format centered on the page.  Uses BasicPage for title layout.

        @title: Text to display at top of card
        @size: Size of card
        @footer: Element[s] to display at bottom of card
        @pageTitle: Override page title with custom text instead of navigation title
    */
    if (!size)
        size = 'sm'

    return (
        <BasicPage pageTitle={pageTitle}>
            <Container maxWidth={size}>
                <Card sx={{ marginTop: "1rem" }}>
                    <CardContent>
                        {title ? <Typography color="primary.main" variant="h4" component="h2">{title}</Typography> : null}
                        {children}
                        <CardActions sx={{marginTop: "1rem"}}>
                            {footer}
                        </CardActions>
                    </CardContent>
                </Card>
            </Container>
        </BasicPage>
    )
}

