import { React, ReactRouter, Typography } from 'mcelroy-lib'

export function BasicPage({ pageTitle, children }) {
    /*!
        Basic page layout.  Displays route title as the page title.
        Includes an actions slot for displaying a toolbar next to the title.

        @pageTitle: Override page title with custom text instead of navigation title
    */
    const pages = ReactRouter.useMatches().filter(match => match.handle?.title)
    const title = pageTitle ? pageTitle : pages && pages.length > 0 ? pages[pages.length - 1].handle.title : ''
    return (
        <page-shell>
            <Typography slot="title" color="gray.main" variant="h3" component="h1" sx={{ margin: "0px" }}>{title}</Typography>
            {children}
        </page-shell>
    )
}

/*
    web component template for structure layout
*/
const template = document.createElement('template')
template.innerHTML = `
<div style="margin: 0">
    <div style="display: flex; justify-content: space-between; margin-left: 1rem; margin-right: 1rem">
        <slot name="title"></slot> <slot name="actions"></slot>
    </div>
    <slot></slot>
</div>
`

/*
    web component for basic page structure
*/
class PageShell extends HTMLElement {
    constructor() {
        super()
        const shadowRoot = this.attachShadow({ mode: "open" })
        shadowRoot.appendChild(template.content.cloneNode(true))
    }
}

customElements.define('page-shell', PageShell)
