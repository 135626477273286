import { React, Divider, Box } from 'mcelroy-lib'
import { FormTextField } from './FormTextField'
import { FormCheckField } from './FormCheckField'

/*
    Form fields layout container
*/
export function Form({ fields, dispatch }) {
    return (
        <Box sx={{display: 'flex', flexFlow: 'row', flexWrap: 'wrap', gap: '10px'}}>
            {
                fields?.map((f, i) => {
                    if (f.fields)
                        return (<FormSection key={i} section={f} dispatch={dispatch} />)
                    else
                        return (<FormField key={i} field={f} dispatch={dispatch} />)
                })
            }
        </Box>
    )
}

/*
    Creates the appropriate form field from the field data
*/
function FormField({ field, dispatch }) {
    if (field.show === false)
        return null
    
    if (field.type === 'checkbox') {
        return(
            <FormCheckField
                    name={field.name}
                    label={field.label}
                    value={field.value}
                    disabled={field.disabled}
                    helperText={field.helperText}
                    dispatch={dispatch}
                />
        )
    } else {
        return (
            <FormTextField
                name={field.name}
                label={field.label}
                value={field.value}
                type={field.type}
                options={field.options}
                disabled={field.disabled}
                helperText={field.helperText}
                validator={field.validator}
                dispatch={dispatch}
            />
        )
    }
}

/*
    Creates a form section with optional label and all fields of that section
*/
function FormSection({ section, dispatch }) {
    if (section.show === false)
        return null

    return (
        <Box sx={{flexBasis: '260px', flexGrow: 1, paddingLeft: section.indent ? '20px' : 0}}>
            {section?.label ? <Divider textAlign="left" sx={{ marginTop: "1rem" }}>{section.label}</Divider> : null}
            {
                section?.fields?.map((f, i) => {
                    if (f.fields)
                        return <FormSection key={i} section={f} dispatch = { dispatch } />
                    else
                        return <FormField key={i} field={f} dispatch={dispatch} />
                })
            }
        </Box>
    )
}
